import { Outlet } from 'react-router-dom';

function ServicesManagementView() {
    return (
        <>
            {/* <div>ServicesManagementView</div> */}

            <Outlet />
        </>
    )
}

export default ServicesManagementView