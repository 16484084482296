import { Outlet, } from 'react-router-dom';

function ProductsManagementView() {
	
	return (
		<>
			{/* <div>ProductsManagementView</div> */}

			<Outlet />
		</>
	)
}

export default ProductsManagementView